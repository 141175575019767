import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Div100Vh from "react-div-100vh"
import { StaticImage } from "gatsby-plugin-image"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import SEO from "../components/seo"
import Layout from "../components/layout"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      ig: file(relativePath: { eq: "ig.svg" }) {
        publicURL
      }
    }
  `)
  return (
    <Layout header={false}>
      <SEO title="Home" />
      <Div100Vh className="index">
        <div className="index-container">
          <StaticImage
            loading="eager"
            placeholder="none"
            draggable={false}
            src="../images/logo.png"
            className="index-logo"
            alt="Carbon Copy Logo"
            width={100}
            formats={["webp", "auto"]}
          />
          <div className="index-spacer" />
          <div className="index-text">
            <h1>carboncopy</h1>
            <p>
              internet presence design and bespoke e-commerce development from{" "}
              <OutboundLink href="https://peter.digital">
                Peter Campanelli
              </OutboundLink>{" "}
              and{" "}
              <OutboundLink href="https://www.pegnosis.com/">
                Praise Godswill
              </OutboundLink>
              .
            </p>
            <p>coming soon</p>
            <p>
              <OutboundLink href="https://instagram.com/carboncopy.online">
                <img
                  draggable={false}
                  src={data.ig.publicURL}
                  className="index-igicon"
                  alt="Instagram"
                />
              </OutboundLink>
            </p>
          </div>
        </div>
      </Div100Vh>
    </Layout>
  )
}

export default IndexPage
